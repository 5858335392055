<script setup lang="ts">
import { ref } from 'vue';
import { useUnreadNotificationsReportStatusStore } from '@/stores/notifications/reportStatusNotificationsStore';
import { useUnreadNotificationEventWizardStatusStore } from '@/stores/notifications/eventWizardNotificationsStore';
import NotificationReportStatusItem from '@/components/notifications/components/notificationReportStatusItem.vue';
import NotificationEventWizardStatusItem from '@/components/notifications/components/notificationEventWizardStatusItem.vue';
import { storeToRefs } from 'pinia';
import type { NotificationMarketingEventItemType } from "@/stores/notifications/eventWizardNotificationsStore";
import type { NotificationReportStatusItemType } from "@/stores/notifications/reportStatusNotificationsStore";
import { notifications } from "@/api/requests/notifications";
import { toastNotification } from "@/utils/toastNotification";
import {  enumWarning } from "@/constants/notifications";


const notificationsReportStore = useUnreadNotificationsReportStatusStore();
const { reportNotifications } = storeToRefs(notificationsReportStore);
const { fetchReportStatusNotifications } = notificationsReportStore

const notificationWizardStore = useUnreadNotificationEventWizardStatusStore();
const { marketingEventsNotifications } = storeToRefs(notificationWizardStore);
const { fetchMarketingEventsNotifications } = notificationWizardStore


const allReportNotifications = ref<NotificationReportStatusItemType[]>([]);
const readReportNotifications = ref<NotificationReportStatusItemType[]>([]);
const marketingEventsAllNotifications = ref<NotificationMarketingEventItemType[]>([]);
const marketingEventsReadNotifications = ref<NotificationMarketingEventItemType[]>([]);

const isLoading = ref(false);
const fetchOtherTypesNotifications = async (readFilter: string, notificationType: string) => {
  let response = [];
  const params = {
    pageSize: 40,
    pageNumber: 1,
    readFilter: readFilter,
    notificationType: notificationType
  };
  isLoading.value = true;
  const { data: { data: { items } }} = await notifications.getNotifications({ params });
  isLoading.value = false;
  response = items;
  return response;
};

const isShowNotificationsType = ref(true);
const isShowUnreadReportNotifications = ref(true);
const isShowAllReportNotifications = ref(false);
const isShowReadReportNotifications = ref(false);
const isShowUnreadEventWizardNotifications = ref(true);
const isShowAllEventWizardNotifications = ref(false);
const isShowReadEventWizardNotifications = ref(false);

const show = () => {
  toastNotification.add(enumWarning.VIEW_FUNCTIONAL, 'warn');
};



const itemsReport = ref([
  {
    label: "Тип уведомлений",
    items: [
      {
        label: "Непрочитанные",
        command: async () => {
          isShowUnreadReportNotifications.value = true;
          isShowReadReportNotifications.value = false;
          isShowAllReportNotifications.value = false;
          allReportNotifications.value = [];
          readReportNotifications.value = [];
        },
      },
      {
        label: "Прочитанные",
        command: async () => {
          isShowReadReportNotifications.value = true;
          isShowUnreadReportNotifications.value = false;
          isShowAllReportNotifications.value = false;
          allReportNotifications.value = [];
          readReportNotifications.value = await fetchOtherTypesNotifications('ReadOnly', 'report_status');
        },
      },
      {
        label: "Все",
        command: async () => {
          isShowAllReportNotifications.value = true;
          isShowReadReportNotifications.value = false;
          isShowUnreadReportNotifications.value = false;
          readReportNotifications.value = [];
          allReportNotifications.value = await fetchOtherTypesNotifications('All', 'report_status');
        }
      }
    ]
  }
]);

const itemsEvent = ref([
    {
      label: "Тип уведомлений",
      items: [
        {
          label: "Непрочитанные",
          command: () => {
            isShowUnreadEventWizardNotifications.value = true;
            isShowAllEventWizardNotifications.value = false;
            isShowReadEventWizardNotifications.value = false;
            marketingEventsReadNotifications.value = [];
            marketingEventsAllNotifications.value = [];
          }
        },
        {
          label: "Прочитанные",
          command: async () => {
            isShowUnreadEventWizardNotifications.value = false;
            isShowAllEventWizardNotifications.value = false;
            isShowReadEventWizardNotifications.value = true;
            marketingEventsAllNotifications.value = [];
            marketingEventsReadNotifications.value = await fetchOtherTypesNotifications('ReadOnly', 'event_wizard_status');
          }
        },
        {
          label: "Все",
          command: async () => {
            isShowUnreadEventWizardNotifications.value = false;
            isShowAllEventWizardNotifications.value = true;
            isShowReadEventWizardNotifications.value = false;
            marketingEventsReadNotifications.value = [];
            marketingEventsAllNotifications.value = await fetchOtherTypesNotifications('All', 'event_wizard_status');
          }
        }
      ]
    }
  ]
);


const menu = ref();
const toggle = (event: any) => {
  menu.value.toggle(event);
};

const readAllNotifications = () => {
  notifications.readNotifications({});
  fetchReportStatusNotifications();
  fetchMarketingEventsNotifications()
}

</script>

<template>
  <div class="notifications p-0">
    <div class="notifications__top flex justify-content-between">
      <span style="margin-left: 20px; font-size: 1.25rem; font-weight: bold">Уведомления</span>
      <div style="width: 75px" class="flex justify-content-between">
        <button class="p-link layout-topbar-button border-circle" @click="show">
          <i style="font-size: 1rem" class="pi pi-external-link p-2 text-color-secondary"></i>
        </button>
        <button class="p-link layout-topbar-button mr-2 border-circle" @click="$emit('notification:close')">
          <i style="font-size: 1rem" class="pi pi-times p-2 text-color-secondary"></i>
        </button>
      </div>
    </div>
    <div class="notifications__middle flex justify-content-between">
      <div class="flex w-full ml-1">
        <Button
          class="flex gap-2"
          type="button"
          label="Выгрузка отчетов"
          @click="isShowNotificationsType = true; isShowUnreadReportNotifications = true; isShowReadReportNotifications = false; isShowAllReportNotifications = false; marketingEventsReadNotifications = []; marketingEventsAllNotifications = [];"
          text outlined
          :badge="reportNotifications.length.toString()"
          badgeClass="surface-ground mt-1 border-round"
        />
        <Button
          class="flex gap-2"
          type="button"
          label="Создание мероприятия"
          @click="isShowNotificationsType = false; isShowUnreadEventWizardNotifications = true; isShowAllEventWizardNotifications = false; isShowReadEventWizardNotifications = false; allReportNotifications = []; readReportNotifications = [];"
          text
          outlined
          :badge="marketingEventsNotifications.length.toString()"
          badgeClass="surface-ground mt-1 border-round"
        />
      </div>
      
      <div style="width: 35px" class="mr-1">
        <button class="p-link layout-topbar-button border-circle" @click="toggle">
          <i style="font-size: 1.2rem" class="pi pi-cog p-2 text-color-secondary" />
        </button>
      </div>
    </div>
    <div class="flex justify-center items-center mx-auto">
        <Button
          class="flex mx-auto gap-2"
          type="button"
          label="Прочитать всё"
          @click="readAllNotifications"
          text
          outlined
          :badge="+(reportNotifications.length.toString()) + +(marketingEventsNotifications.length.toString())"
          badgeClass="surface-ground mt-1 border-round"
        />
      </div>
    <div class="notifications__bottom">
      <ScrollPanel style="width: 460px; height: 60vh" class="custombar">
        <div v-if="isLoading">
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        <!-- todo: за v-if и v-for вместе будет страшная кара -->
        <div v-if="isShowNotificationsType">
          <Menu ref="menu" :model="itemsReport" :popup="true" @click.stop />
          <div v-if="isShowAllReportNotifications">
            <NotificationReportStatusItem :notification="notification" v-for="notification in allReportNotifications" />
          </div>
          <div v-if="isShowReadReportNotifications">
            <NotificationReportStatusItem :notification="notification" v-for="notification in readReportNotifications" />
          </div>
          <div v-if="isShowUnreadReportNotifications">
            <div v-if="reportNotifications.length">
              <NotificationReportStatusItem
                :show-read-button="true"
                @fetchNotification="fetchReportStatusNotifications"
                :notification="notification"
                v-for="notification in reportNotifications"
              />
            </div>
            <div class="flex justify-content-center h-30rem" v-else>
              <span class="font-medium text-lg align-items-center flex">Новых уведомлений нет</span>
            </div>
          </div>
        </div>
        <div v-if="!isShowNotificationsType">
          <Menu ref="menu" :model="itemsEvent" :popup="true" @click.stop />
          <div v-if="isShowAllEventWizardNotifications">
            <NotificationEventWizardStatusItem :notification="notification" v-for="notification in marketingEventsAllNotifications" />
          </div>
          <div v-if="isShowReadEventWizardNotifications">
            <NotificationEventWizardStatusItem :notification="notification" v-for="notification in marketingEventsReadNotifications" />
          </div>
          <div v-if="isShowUnreadEventWizardNotifications">
            <div v-if="marketingEventsNotifications.length">
              <NotificationEventWizardStatusItem
                :show-read-button="true"
                @fetchNotification="fetchMarketingEventsNotifications"
                :notification="notification"
                v-for="notification in marketingEventsNotifications"
              />
            </div>
            <div class="flex justify-content-center h-30rem" v-else>
              <span class="font-medium text-lg align-items-center flex">Новых уведомлений нет</span>
            </div>
          </div>
        </div>
      </ScrollPanel>
    </div>
  </div>
</template>

<style scoped lang="scss">

.notifications {
  &__top {
    align-items: center;
    border-bottom: 1px solid var(--surface-d);
    height: 50px;
  }

  &__middle {
    align-items: center;
    height: 55px;
    border-bottom: 1px solid var(--surface-d);
  }

  &__bottom {
    max-height: 450px;
    min-height: 500px;

  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: flex;
    position: absolute;
    left: 200px;
    top: 250px;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--surface-300);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--surface-300) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>